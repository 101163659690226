import React from 'react'
import { Grid } from '@mui/material'
import anos from './dege.png'
import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import c1 from './phantom.png'
import c2 from './solana.png'
import c3 from './rads.webp'
import c4 from './deb.png'
import ch1 from './c1.png'
import ch2 from './c2.png'
function Desktop() {
  return (
    <div className='container'>
      <br/>
          <Grid container className='backall'>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
          <a href="https://dexscreener.com/solana/dupgcb7foukse11zx1huvkrel9g2cturgssguxnjmqvp" class="matrix-text "
          style={{textDecoration:"none"}}
          
          >
      Chart</a>&nbsp;&nbsp;
         <a href="https://twitter.com/solanaDEGEN69"  class="matrix-text "
          style={{textDecoration:"none"}}
          
          > Twitter</a>&nbsp;&nbsp;
             
             <a href="https://t.me/soldegen69"  class="matrix-text "
          
          > Telegram</a>

&nbsp;&nbsp;
<a href=""  class="matrix-text "
          style={{textDecoration:"none"}}
          target="_ blank"
          > Buy </a>
         </Grid>
         <Grid item md={12} xs={12} lg={12} className='centerall'>
          <a href="https://www.dextools.io/app/en/solana/pair-explorer/DuPgCB7foukse11ZX1huVKReL9G2CtURgSsGUxnjmqvp?t=1710746993875" class="matrix-text "
          style={{textDecoration:"none"}}
          
          >
     Dextools</a>&nbsp;&nbsp;
     <br/>
     <br/><br/>
<br/>
         </Grid>
         <br/>
          </Grid>
       
          <br/>
          <Grid container>
          <Grid item md={6} xs={12} lg={6} className='centerall'>
                <img src={anos} className='image_anos'/>
         </Grid>
         <Grid item md={6} xs={12} lg={6} className='centerall'>
          <br/>  
         <h1 class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_text">$ Degen</h1>
         <p className='anon_p'>
         Buy $DEGEN now n change ur lyf </p>
         <p className='anon_p'>
       CA : 2q6yp2HxYQnvtRcsb1ax7R8ZuGBHVfa8QswnuLGKVEhx</p>
       <div  className='centerall'>
          
      
    
         
          &nbsp;&nbsp;
             <a href="https://dexscreener.com/solana/dupgcb7foukse11zx1huvkrel9g2cturgssguxnjmqvp"  class="matrix-text "
              style={{textDecoration:"none"}}
              
              > <img src={ch1} style={{width:"40px"}}/></a>&nbsp;&nbsp;
                 
                 <a href="https://www.dextools.io/app/en/solana/pair-explorer/DuPgCB7foukse11ZX1huVKReL9G2CtURgSsGUxnjmqvp?t=1710746510941"  class="matrix-text "
              
              > <img src={ch2} style={{width:"30px"}}/></a>
    
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    
             </div>
         <button>
          
    <span>Buy $Degen</span>
</button>
         </Grid>
     
          </Grid>

          <br/>  <br/>


          <Grid container>
          
         <Grid item md={12} xs={12} lg={12} className='centerall'>
          <h1 class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textg"> 

          " $DEGEN, one transaction away from degenerational wealth "</h1>
         </Grid>

       
          </Grid>
          <Grid container spacing={2}>
          
          <Grid item md={12} xs={12} lg={12} className='centerall'>
           <h1 style={{fontWeight:"900"}} class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textg"> 
 
       About $Degen</h1>
          </Grid>
 
        <Grid item md={12} xs={12} lg={12} className='centerall'>

        <div class="cardabout shadow">
        <br/>
        <div className='container'>
     
         
          <p className='htb_hp'>
          When I wake up I don’t ever think I dont shit I dont shower I don’t brush my teeth I dont eat I just live I must make gains I have to ape this one too I must pull up the live chart, 1s scale is good, I need green candles my theories are correct this is the next $PEPE, lfg to the moon there is no fuking way this fucking rugs oh shit its fucking sending lfg wagmi you fucking mother fuckers I told you this is it its so joever im calling my boss fuck you fucking piece of shit im quitting i hate you all especially you Janet you fat piece of shit

          </p>
          <br/> 

        </div>
          
</div>
        </Grid>





           </Grid>
          <br/><br/> 
          <Grid container spacing={2}>
          
          <Grid item md={12} xs={12} lg={12} className='centerall'>
           <h1 style={{fontWeight:"900"}} class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textg"> 
 
        How to Buy</h1>
          </Grid>
 
        <Grid item md={6} xs={12} lg={6} className='centerall'>

        <div class="card shadow">
        <br/>
        <div className='container'>
        <h4 className='htb_h'>Create a Wallet</h4>
          <div className='centerall'> <img src={c1} style={{width:"50px"}} /></div>
          <p className='htb_hp'>Download Phantom or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to phantom.app.</p>
         

        </div>
          
</div>
        </Grid>

        <Grid item md={6} xs={12} lg={6} className='centerall'>

<div class="card shadow">
<br/>
<div className='container'>
<h4 className='htb_h'>
Get Some SOL</h4>
  <div className='centerall'> <img src={c2} style={{width:"50px"}} /></div>
  <p className='htb_hp'>

  Have SOL in your wallet to switch to $Degen. If you don’t have any SOL, you can buy SOL from an exchange or cross chain swap and send it to your wallet.
  </p>
 

</div>
  
</div>
</Grid>

<Grid item md={6} xs={12} lg={6} className='centerall'>

<div class="card shadow">
<br/>
<div className='container'>
<h4 className='htb_h'>
Go to Raydium</h4>
  <div className='centerall'> <img src={c3} style={{width:"50px"}} /></div>
  <p className='htb_hp'>

  Connect to Raydium . Go raydium.io  in google chrome or on the browser inside your Phantom app. Connect your wallet. Paste the $Degen token address into Raydium and confirm the swap. When Phantom prompts you for a wallet signature, sign.
  </p>
 

</div>
  
</div>
</Grid>


<Grid item md={6} xs={12} lg={6} className='centerall'>

<div class="card shadow">
<br/>
<div className='container'>
<h4 className='htb_h'>
Switch SOL for degen</h4>
  <div className='centerall'> <img src={c4} style={{width:"60px" , border:"2px solid #000" , borderRadius:"100px"}} /></div>
  <br/>
  <p className='htb_hp'>

  Now paste Degen Contract Address on Raydium and swap your Sol to $Degen
  </p>
 

</div>
  
</div>
</Grid>
           </Grid>

          


         
           <br/><br/>
           <Grid container>
          
          <Grid item md={12} xs={12} lg={12} className='centerall'>
           <h1 class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textg"> 
 
         Join $Degen</h1>

         <br/>


         <div className='container centerall'>
          <a href='https://twitter.com/solanaDEGEN69'>
            <BsTwitterX style={{color:"#000"}}/>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
          <a href='https://t.me/soldegen69'> 
          <FaTelegramPlane style={{color:"#000"}}/>
          </a>

         </div>
          </Grid>
 
        
           </Grid>
           <br/>
           <p className='center_dis'>Disclaimer
            <br/><br/>
             The content provided on this website, including information about $DEGEN (Degen Coin), is intended for informational purposes only and should not be construed as financial advice. Investing in cryptocurrencies involves inherent risks, including the potential loss of principal. </p>
<br/>  <br/>  <br/>
    </div>
  )
}

export default Desktop